import { default as _91_46_46_46pageSlug_93ldGvB6TqX5Meta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activateZcRlOnToNhMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/activate.vue?macro=true";
import { default as indexlTS1IZxh6bMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93ZzDa8ZXnhpMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newudwmmRv1pEMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlist6Drm18l11oMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/watchlist.vue?macro=true";
import { default as indexpAy0w7ZM1WMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/index.vue?macro=true";
import { default as edit63GETShrrnMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as index3k6QjvswYpMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addHbxpgatvBYMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflesxXuH2v8O9mMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as faqCU4kibT2d1Meta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as infor1TkaF7DUBMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as _91showGroupSlug_93PzUW82tahuMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as program4jp4qbALQ4Meta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopjjQ55B5eKdMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_938vBJDscGTrMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as _91showSlug_93XknkFb00SAMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchersFJ8L78yxIeMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93tLGNAUOwfoMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexmlQcoFz4L3Meta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93uBxbuugitDMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasfgQQuDK3SUMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinemas.vue?macro=true";
import { default as cities00lhEWf73oMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cities.vue?macro=true";
import { default as indexksGKZ56cF8Meta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/index.vue?macro=true";
import { default as _91movieSlug_930yW8Pham11Meta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexThEY4xe65TMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_9384AjGeiLQ8Meta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresMjVwZhiGkdMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genres.vue?macro=true";
import { default as index8gn1Z74i6aMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/index.vue?macro=true";
import { default as indexVjYqHmeDCsMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_93hNVZTDyFm7Meta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personselC7lZZcAnMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/persons.vue?macro=true";
import { default as searchhzl2LA4prTMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/search.vue?macro=true";
import { default as vouchershN6AQehxfqMeta } from "/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexpAy0w7ZM1WMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexpAy0w7ZM1WMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexpAy0w7ZM1WMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexpAy0w7ZM1WMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexpAy0w7ZM1WMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexpAy0w7ZM1WMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexpAy0w7ZM1WMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexpAy0w7ZM1WMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: edit63GETShrrnMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: edit63GETShrrnMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: edit63GETShrrnMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: edit63GETShrrnMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: edit63GETShrrnMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: edit63GETShrrnMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: edit63GETShrrnMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: edit63GETShrrnMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index3k6QjvswYpMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index3k6QjvswYpMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index3k6QjvswYpMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index3k6QjvswYpMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index3k6QjvswYpMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index3k6QjvswYpMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index3k6QjvswYpMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index3k6QjvswYpMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addHbxpgatvBYMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addHbxpgatvBYMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addHbxpgatvBYMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addHbxpgatvBYMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addHbxpgatvBYMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addHbxpgatvBYMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addHbxpgatvBYMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addHbxpgatvBYMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesxXuH2v8O9mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesxXuH2v8O9mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesxXuH2v8O9mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesxXuH2v8O9mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesxXuH2v8O9mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesxXuH2v8O9mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: rafflesxXuH2v8O9mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: rafflesxXuH2v8O9mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: _91citySlug_93uBxbuugitDMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93uBxbuugitDMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93uBxbuugitDMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93uBxbuugitDMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93uBxbuugitDMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93uBxbuugitDMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93uBxbuugitDMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93uBxbuugitDMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: _91genreSlug_9384AjGeiLQ8Meta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_9384AjGeiLQ8Meta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_9384AjGeiLQ8Meta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_9384AjGeiLQ8Meta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_9384AjGeiLQ8Meta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_9384AjGeiLQ8Meta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_9384AjGeiLQ8Meta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_9384AjGeiLQ8Meta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: _91personSlug_93hNVZTDyFm7Meta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93hNVZTDyFm7Meta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93hNVZTDyFm7Meta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93hNVZTDyFm7Meta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93hNVZTDyFm7Meta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93hNVZTDyFm7Meta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93hNVZTDyFm7Meta?.name,
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93hNVZTDyFm7Meta?.name,
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2024-12-11_17-32-02_2362790/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]